import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import TestimonialSection from "../components/testimonials-section"
import Cta from "../components/cta"
import Seo from "../components/seo"
import ArrowRight from "../components/svg/arrowRight"

const AppDevPage = () => (
    <Layout>
        <Seo 
            title="React Native developers" 
            description="Launch Lab offers React Native app development and React.js web development. We're highly experienced onshore Javascript developers."
            pathname="/app-developer-sydney/"
        />

        <Hero
            h1="React Native App Developers"  
            h2="We develop iOS &amp; Android apps in Australia using React Native"
        />

        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="below-hero"
                        data-sal="slide-right" 
                        data-sal-easing="ease-in-out"
                        data-sal-duration="400"
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">Startups</span>
                            <p>If React Native meets the requirements of your idea, it's perfect to get you started. It's a quicker and more cost effective way to develop your MVP.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">React Native</span>
                            <p>RN is Javascript so there's need for separate iOS and Android developers. If your app isn't suited to RN we'll tell you.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Python</span>
                            <p>The apps we develop are all database driven. We develop backends for our apps using Python and AWS.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >Mobile apps for startups</h3>
                        </div>

                        <div className="col-67 long-text"
                            data-sal="slide-up" 
                            data-sal-easing="ease-in-out"
                            data-sal-duration="400"
                        >
                            <p>
                                Launch Lab are app developers in Sydney &amp; Canberra. We build server driven mobile apps for startups, in Australia, using React Native.
                            </p>
                            <p>
                                We've developed all sorts of apps over the years. From apps for leading financial institutions, to tablet apps used at events by a multinational brand and fintech startup iOS and Android apps.
                            </p>
                            <p>
                                If you're a startup founder working on a mobile app for the first time, we'll guide you through the process and help advise on the optimal development path to market.
                            </p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                    Start your project
                                    <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="Ready to discuss your app development project?"
        />
    </Layout>
)

export default AppDevPage